exports.components = {
  "component---src-templates-archive-tsx": () => import("./../../../src/templates/archive.tsx" /* webpackChunkName: "component---src-templates-archive-tsx" */),
  "component---src-templates-article-tsx": () => import("./../../../src/templates/article.tsx" /* webpackChunkName: "component---src-templates-article-tsx" */),
  "component---src-templates-category-tsx": () => import("./../../../src/templates/category.tsx" /* webpackChunkName: "component---src-templates-category-tsx" */),
  "component---src-templates-page-noside-tsx": () => import("./../../../src/templates/page-noside.tsx" /* webpackChunkName: "component---src-templates-page-noside-tsx" */),
  "component---src-templates-page-tsx": () => import("./../../../src/templates/page.tsx" /* webpackChunkName: "component---src-templates-page-tsx" */),
  "component---src-templates-tag-tsx": () => import("./../../../src/templates/tag.tsx" /* webpackChunkName: "component---src-templates-tag-tsx" */),
  "component---src-templates-taglist-tsx": () => import("./../../../src/templates/taglist.tsx" /* webpackChunkName: "component---src-templates-taglist-tsx" */),
  "component---src-templates-top-tsx": () => import("./../../../src/templates/top.tsx" /* webpackChunkName: "component---src-templates-top-tsx" */)
}

